// Angular Core
import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// NgRx
import { Store, select } from '@ngrx/store';
import { AppState, isLoggedIn, Logout } from './auth.store';
// RxJS & Lodash
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { get } from 'lodash';
// Services
import { GlobalErrorHandler, CommonService, SentryService, WebSocketService } from '@core/utils';
import { AuthService } from '@auth-service';
// Environment
import { environment } from '@environment';

/**
 * Functional Auth Guard
 */
export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {

  // ✅ Inject dependencies (no constructor needed)
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const authService = inject(AuthService);
  const globalErrorHandler = inject(GlobalErrorHandler);
  const commonService = inject(CommonService);
  const sentryService = inject(SentryService);
  const wsService = inject(WebSocketService);

  return store.pipe(
    select(isLoggedIn),
    switchMap((islogin) => {
      const requestToServer = islogin ? authService.getRoleAndPermission() : of(false);
      return forkJoin([of(islogin), requestToServer]);
    }),
    map((response) => {
      return handleResponse(response, authService, wsService, router, store, commonService, sentryService, globalErrorHandler);
    }),
    catchError((error) => {
      handleUnauthorizedAccess(authService, store, router, commonService, sentryService, globalErrorHandler);
      return globalErrorHandler.handleError('auth-guard-role-permission', error, true);
    })
  );
};

/**
 * Handles the authentication response
 */
function handleResponse(
  response: any,
  authService: AuthService,
  wsService: WebSocketService,
  router: Router,
  store: Store<AppState>,
  commonService: CommonService,
  sentryService: SentryService,
  globalErrorHandler: GlobalErrorHandler
): boolean {
  const isLogged = response[0];
  if (isLogged) {
    const rolePermissions = response[1];
    if (rolePermissions.success) {
      const userRole = get(rolePermissions, 'data.role');
      const title = get(userRole, 'role');
      const permissions = get(userRole, 'permission', []);
      authService.loadRolesWithPermissions(title, permissions);
      if (environment.isWebSocketEnabled) wsConnection(wsService);
    } else {
      handleUnauthorizedAccess(authService, store, router, commonService, sentryService, globalErrorHandler);
    }
  } else {
    handleUnauthorizedAccess(authService, store, router, commonService, sentryService, globalErrorHandler);
  }
  return isLogged;
}

/**
 * Handles WebSocket connection
 */
function wsConnection(wsService: WebSocketService) {
  wsService.connect().subscribe({
    next: (data) => {
      // Use Signal API to share data between components (if needed)
    },
    error: (error) => console.error('WebSocket failed after retries:', error),
  });
}

/**
 * Handles Unauthorized Access
 */
function handleUnauthorizedAccess(
  authService: AuthService,
  store: Store<AppState>,
  router: Router,
  commonService: CommonService,
  sentryService: SentryService,
  globalErrorHandler: GlobalErrorHandler
): void {
  try {
    store.dispatch(new Logout());
    commonService.clearAll();
    authService.flushRoleAndPermissions();
    sentryService.resetAll();
    const returnUrl = window.location?.pathname !== '/' ? window.location.pathname + window.location.search : undefined;
    router.navigate(['/auth/login'], { queryParams: { returnUrl } });
  } catch (error) {
    globalErrorHandler.handleError('auth-guard-handle-unauthorized-access', error);
  }
}
