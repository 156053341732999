// Angular
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
// Service
import { GlobalErrorHandler } from '@core/utils';
import { AuthService } from '@auth-service';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// RxJs
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Model
import { IConfirmUser } from 'src/app/core/auth';
// Lodash
import { get, trim } from 'lodash';

@Component({
  selector: 'app-confirm-user-auth-password',
  templateUrl: './confirm-user-auth-password.component.html',
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class ConfirmUserAuthPasswordComponent implements OnInit, OnDestroy {
  private readonly dialogRef = inject<MatDialogRef<ConfirmUserAuthPasswordComponent>>(MatDialogRef);
  private readonly globalErrorHandler = inject(GlobalErrorHandler);
  private readonly fb = inject(FormBuilder);
  private readonly auth = inject(AuthService);
  private readonly snackBar = inject(MatSnackBar);
  readonly data = inject(MAT_DIALOG_DATA);


  // Public Variables
  validatePasswordForm: FormGroup;
  loading = false;

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * On Init
   */
  ngOnInit(): void {
    this.validatePasswordForm = this.fb.group({
      password: ['', Validators.required]
    });
  }

  /**
   * On Destroy
   * @summary To avoid memory leak destroy component subscription
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * on confirm password
   * @returns void
   */
  onConfirm(): void {
    if (this.validatePasswordForm.invalid) { return; }
    this.loading = true;
    const payload: IConfirmUser = { input_password: trim(this.validatePasswordForm.controls.password.value) };
    const confirmUserIdentitySubscription = this.auth.confirmUserIdentity(payload).pipe(
      catchError(error => this.globalErrorHandler.handleError('on-confim-confirm-user-identity-verification', error)),
    ).subscribe(response => {
      this.handleResponse(response);
      this.loading = false;
    });
    this.subscriptions.push(confirmUserIdentitySubscription);
  }

  /**
   * on close dialog
   * @returns void
   */
  onClose(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle response of confirm user verification
   * @returns void
   */
  private handleResponse(response: any): void {
    const isValid: boolean = get(response, 'success', false);
    if (isValid) {
      this.snackBar.open('Identity verified successfully', 'Close', {
        panelClass: ['success-dialog']
      });
    } else {
      this.snackBar.open('Identity verification failed', 'Close', {
        panelClass: ['danger-dialog']
      });
    }
    this.dialogRef.close(isValid);
  }

}
