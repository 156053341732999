// Angular
import { Injectable, inject } from '@angular/core';
// RxJs
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Constants
import { APP_CONSTANT, API_URL } from '@constants';
// Service
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { HttpUtilsService, CommonService, GlobalErrorHandler } from '@core/utils';
// Models
import { User } from '@sentry/angular';
import { IUserLogin, IConfirmUser, ILoginParams } from 'src/app/core/auth';

// common header
const HEADER_ACCEPT_JSON = { 'accept': 'application/json' };

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly httpUtilsService = inject(HttpUtilsService);
  private readonly cs = inject(CommonService);
  private readonly globalErrorHandler = inject(GlobalErrorHandler);
  private readonly ngxRolesService = inject(NgxRolesService);
  private readonly ngxPermissionsService = inject(NgxPermissionsService);


  /**
   * Login request
   * @param {IUserLogin} loginData
   * @param {ILoginParams} [params]
   * @returns {Observable<User>}
   * @memberof AuthService
   */
  public login(loginData: IUserLogin, params?: ILoginParams): Observable<User> {
    return this.httpUtilsService
      .postData<User>(API_URL.LOGIN_URL, loginData, params, HEADER_ACCEPT_JSON)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError(`user-login | ${loginData.username}`, error, true))
      );
  }

  /*
   * Get User details from localstorage
   * @returns {Observable<any>}
   */
  public getUserDetails(): Observable<any> {
    const user = this.cs.getFromLocalStorage(APP_CONSTANT.LOGGED_USER);
    let observableResult = of({ type: 'NO_DATA' });
    if (user) {
      observableResult = of(user);
    }
    return observableResult;
  }

  /*
   * Submit forgot password request
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.httpUtilsService
      .postData(API_URL.RESET_PWD_URL, { email }, null, HEADER_ACCEPT_JSON)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError(`reset-password | ${email}`, error, true))
      );
  }

  /**
  * Change Account Password
  * @param payload: any
  * @returns Observable<any>
  */
  public changePassword(payload: any): Observable<any> {
    return this.httpUtilsService
      .postData(API_URL.RECOVER_PWD_URL, payload, null, HEADER_ACCEPT_JSON)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError('change-password', error, true))
      );
  }

  /*
   * Get Role And Permission
   * Error handling is done inside auth guard
   * @returns {Observable<any>}
   */
  public getRoleAndPermission(): Observable<any> {
    return this.httpUtilsService.getData(API_URL.USER_PROFILE, null, HEADER_ACCEPT_JSON);
  }

  /*
   * On confirm user identity verification
   * @returns {Observable<any>}
   */
  public confirmUserIdentity(payload: IConfirmUser): Observable<any> {
    return this.httpUtilsService
      .postData(API_URL.CONFIRM_USER_AUTH, payload)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError('confirm-user-identity-verification', error, true))
      );
  }

  /**
   * NGX Permissions, init roles
   * @param role: string : Example: SUPERADMIN/ ADMIN / MANAGER
   * @param permissions:string[]
   */
  public loadRolesWithPermissions(role: string, permissions: string[]): void {
    this.ngxPermissionsService.loadPermissions(permissions);
    this.ngxRolesService.addRole(role, permissions);
  }

  /**
   * NGX Flush all Role and Permissions
   */
  public flushRoleAndPermissions(): void {
    this.ngxPermissionsService.flushPermissions();
    this.ngxRolesService.flushRoles();
  }
}
