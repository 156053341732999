// Angular
import { Component, OnDestroy, viewChild, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
// Store
import { AppState } from 'src/app/core/reducers';
import { Store } from '@ngrx/store';
import { Login } from 'src/app/core/auth';
// RxJs & Lodash
import { Subject } from 'rxjs';
import { tap, takeUntil, catchError } from 'rxjs/operators';
import { get } from 'lodash';
// Model
import { IUserLogin } from 'src/app/core/auth';
// Service
import { GlobalErrorHandler } from '@core/utils';
import { AuthService } from '@auth-service';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// Transloco
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

@Component({
    selector: 'app-logout-confirmation',
    templateUrl: './switch-company.component.html',
    imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      MatDialogModule,
      MatSnackBarModule,
      MatButtonModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      TranslocoDirective
  ]
})
export class SwitchCompanyComponent implements OnDestroy {
  private readonly dialogRef = inject<MatDialogRef<SwitchCompanyComponent>>(MatDialogRef);
  private readonly router = inject(Router);
  private readonly auth = inject(AuthService);
  private readonly globalErrorHandler = inject(GlobalErrorHandler);
  private readonly store = inject<Store<AppState>>(Store);
  private readonly snackBar = inject(MatSnackBar);
  private readonly translocoService = inject(TranslocoService);
  private document = inject<Document>(DOCUMENT);
  readonly data = inject(MAT_DIALOG_DATA);


  // Public
  isDefault = false;
  loading = false;

  // ViewChild
  readonly form = viewChild<NgForm>('form');

  // Private
  private unsubscribe = new Subject<void>();

  /**
   * On Destroy
   * @summary To avoid memory leak destroy component subscription
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * switch company access to requested company
   * @param e: Event
   * @returns void
   */
  onConfirm(e: Event): void {
    const element = this.document.getElementById('kt_app_root');
    if (element) { element.classList.add('opacity-50'); }
    this.loading = true;
    const switchData: IUserLogin = {
      username: null,
      password: null,
      company_id: get(this.data, 'switchTo.id'),
      company_default: this.isDefault,
    };
    this.auth.login(switchData).pipe(
      tap((response: any) => this._handleSwitchResponse(response)),
      catchError(error => this.globalErrorHandler.handleError('on-confirm-switch-company-access', error)),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  /**
   * Handle switch company access to requested company response
   * @param response: any
   * @returns void
   */
  private _handleSwitchResponse(response: any): void {
    if (response && response.success) {
      this.snackBar.open(this.translocoService.translate('dynamic_accessing_company_now_message', { name: this.data.switchTo.name }), 'Close', {
        panelClass: ['success-dialog']
      });
      this.store.dispatch(
        new Login({
          user: get(response, 'data'),
          authToken: get(response, 'data.token.legacy'),
        })
      );
      this.router.navigate(['/redirect'], {
        queryParams: { returnUrl: null },
      }).then(() => {
        this.loading = false;
        this.dialogRef.close();
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.document.location.reload();
      });
    } else {
      this.loading = false;
      this.snackBar.open(this.translocoService.translate('sentence_something_went_wrong'), 'Close', {
        panelClass: ['danger-dialog']
      });
      const element = this.document.getElementById('kt_app_root');
      if (element) {
        element.classList.remove('opacity-50');
      }
    }
  }
}
